<template>
  <div class="lawyer">
    <div class="local red" v-if="post.city != ''">{{ post.city }}</div>
    <div class="photo-box">
      <router-link :to="`/orgdetails/${post.provider_id}`"><img :src="getOrgPhotoURL(post.provider_id, post.cover)" /></router-link>
    </div>
    <p class="title">{{ post.provider_name }}</p>
    <p class="tel">联系电话：{{ post.phone }}</p>
    <p class="address">地址：{{ post.description }}</p>
    <div class="tag_box">
      <div class="ghost">
        <span>
          <router-link :to="`/orgdetails/${post.provider_id}`">详情</router-link>
        </span>
        <span @click="zanWechat('/zan')"><a><a-icon type="like" /><i>{{ post.like_num }}</i></a></span>
      </div>
    </div>
  </div>
</template>
<script>
import base from '@/core/base.js'
import model from '../../core/model.js'
export default {
  name: 'orgzan',
  mixins: [base, model],
  props: [
    'post'
  ],
  data () {
    return {
      formbase: {
        host_id: '',
        host_type: ''
      }
    }
  },
  methods: {
    // 点赞判断登录
    zanWechat (url) {
      var that = this
      this.$modal.confirm({
        content: h => <div><img src="https://tva1.sinaimg.cn/large/008eGmZEly1gmzwq90m5sj308s08w0v1.jpg" width="150px"/></div>,
        okText: '确认',
        title: '点赞需要您关注公众号后操作',
        onOk () {
          // setCookie('gohistory', to.path)
          that.zanLogin(url)
        },
        onCancel () {
          return false
        }
      })
    },
    zanLogin (url) {
      var that = this
      if (that.$Cookies.get('userData') === '' || that.$Cookies.get('userData') === undefined) {
        that.$modal.confirm({
          content: h => <div>当前操作需要登录后进行</div>,
          okText: '去登录',
          cancelText: '返回',
          onOk () {
            that.$Cookies.set('gopath', url)
            that.$router.push('/login')
          },
          onCancel () {
          }
        })
      } else {
        that.$message.success('点赞成功')
        var formdata = that.formbase
        formdata.host_id = that.post.provider_id
        formdata.host_type = 'provider'
        var res = that.saveData({ url: '/praise', data: formdata })
        if (res) {
          that.post.like_num = that.post.like_num + 1
        }
      }
    }
  }
}
</script>
