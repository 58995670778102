<template>
<page title="点赞">
    <div class="zandiv">
    <commonbanner></commonbanner>
        <div class="container">
          <div class="subtitle"><h2>机构点赞</h2><hr class="underline"></div>
            <div class="mechanism_list ">
                <div class="tabs">
                    <div class="screen_box mt-5">
                        <div>
                         <div class="screen_item">
      <Collection name="serve_org_type" v-slot="{ collection }">
        <h2 class="screen_title pattern">机构类型:</h2>
        <p
          class="select_item"
          :class="{ active: providerQuery.provider_type === '' }"
          @click="remove('provider_type')"
        >
          不限
        </p>
        <p
          class="select_item"
          v-for="item in collection"
          :class="{ active: item.item_value === providerQuery.provider_type }"
          :key="item.item_value"
          @click="
            setprovider_type('provider_type', item.item_value)
          "
        >
          {{ item.item_name }}
        </p>
      </Collection>
    </div>
                        </div>
    <City @change="handleCityChange"></City>
                    </div>
                </div>
          </div>
          <div class="mechanism_list ">
              <Collection
                name="provider"
                :page="provider.current"
                :query="{
                  city: providerQuery.city,
                  area: providerQuery.area,
                  provider_type: providerQuery.provider_type,
                  provider_name: providerQuery.provider_name
                }"
                v-slot="{ collection, count }"
              >
                <a-row :gutter="[16, 16]" class="mt-5">
                  <a-col
                    :span="6"
                    v-for="item in collection"
                    :key="item.provider_id"
                  >
                    <Org
                      :post="item"
                    >
                    </Org>
                  </a-col>
                </a-row>

                <a-pagination
                  class="mb-5"
                  show-quick-jumper
                  :current="provider.current"
                  :defaultPageSize="provider.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
              </Collection>
          </div>
        </div>
    </div>
</page>
</template>
<script>
import Org from '@/components/business/Orgzan'
import City from '@/components/business/City'
import Collection from '@/components/Collection'
import Commonbanner from '@/components/base/Commonbanner'
export default {
  components: {
    Commonbanner,
    Collection,
    Org,
    City
  },
  data () {
    return {
      provider: {
        current: 1,
        defaultPageSize: 12
      },
      person: {
        current: 1,
        defaultPageSize: 12
      },
      providerQuery: {
        city: '',
        area: '',
        provider_name: '',
        person_name: '',
        provider_type: ''
      }
    }
  },
  methods: {
    onChange (page, pageSize) {
      this.provider.current = page
    },
    setprovider_type (key, name) {
      this.providerQuery.provider_type = name
    },
    handleCityChange (obj) {
      this.providerQuery.city = obj.city
      this.providerQuery.area = obj.area
    },
    remove (key) {
      this.providerQuery[key] = ''
    }
  }
}
</script>
<style scoped src="../../assets/css/zan.css"></style>
