var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"title":"点赞"}},[_c('div',{staticClass:"zandiv"},[_c('commonbanner'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"subtitle"},[_c('h2',[_vm._v("机构点赞")]),_c('hr',{staticClass:"underline"})]),_c('div',{staticClass:"mechanism_list "},[_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"screen_box mt-5"},[_c('div',[_c('div',{staticClass:"screen_item"},[_c('Collection',{attrs:{"name":"serve_org_type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [_c('h2',{staticClass:"screen_title pattern"},[_vm._v("机构类型:")]),_c('p',{staticClass:"select_item",class:{ active: _vm.providerQuery.provider_type === '' },on:{"click":function($event){return _vm.remove('provider_type')}}},[_vm._v(" 不限 ")]),_vm._l((collection),function(item){return _c('p',{key:item.item_value,staticClass:"select_item",class:{ active: item.item_value === _vm.providerQuery.provider_type },on:{"click":function($event){return _vm.setprovider_type('provider_type', item.item_value)}}},[_vm._v(" "+_vm._s(item.item_name)+" ")])})]}}])})],1)]),_c('City',{on:{"change":_vm.handleCityChange}})],1)])]),_c('div',{staticClass:"mechanism_list "},[_c('Collection',{attrs:{"name":"provider","page":_vm.provider.current,"query":{
                  city: _vm.providerQuery.city,
                  area: _vm.providerQuery.area,
                  provider_type: _vm.providerQuery.provider_type,
                  provider_name: _vm.providerQuery.provider_name
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var collection = ref.collection;
                var count = ref.count;
return [_c('a-row',{staticClass:"mt-5",attrs:{"gutter":[16, 16]}},_vm._l((collection),function(item){return _c('a-col',{key:item.provider_id,attrs:{"span":6}},[_c('Org',{attrs:{"post":item}})],1)}),1),_c('a-pagination',{staticClass:"mb-5",attrs:{"show-quick-jumper":"","current":_vm.provider.current,"defaultPageSize":_vm.provider.defaultPageSize,"total":count},on:{"change":_vm.onChange}})]}}])})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }